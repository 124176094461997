import React, { useContext } from "react";
import queryString from "query-string";

import useViewport from "./utils/viewportHook";
import { LayerContext } from "./layers";
import LayeredMap from "./LayeredMap";
import { FirmLayerConfig } from "./LayeredMap/firmLayer";
import {
  ActiveFloodLayerBadge,
  Container,
  Expand,
  SquareLink,
} from "./__styles__/Preview";
import { getPath } from "common/routing";
import { useLocation } from "react-router-dom";
import { getCurrentURL } from "../../utils/url";
import { track } from "../../utils/tracking";
import { Icon } from "../Common/Icons/LucideIcons";

interface Props {
  height: string;
  width: string;
  latitude: number;
  longitude: number;
  zoom?: number;
  account: {
    id: string;
    defaultBaseMap: {
      mapboxStyle: Record<string, any>;
    };
    firms: FirmLayerConfig["firms"];
  };
}

export default ({
  height,
  width,
  latitude,
  longitude,
  zoom = 18,
  account,
}: Props) => {
  const { viewport, setViewport } = useViewport({ zoom, latitude, longitude });
  const { visibleFIRM, visibleRaster } = useContext(LayerContext);
  const prevLocation = getCurrentURL(useLocation());

  const layers = {
    parcels: { value: { point: { latitude, longitude } } },
    firms: { firms: account.firms },
    marker: { value: { latitude, longitude } },
  };

  const trackExpandAction = () => {
    track("Map Expanded", {
      source: "propertyProfile",
    });
  };

  const activeFloodLayerName =
    visibleFIRM()?.name ?? visibleRaster()?.layerName;

  return (
    <Container>
      <LayeredMap
        width={width}
        height={height}
        setViewport={setViewport}
        viewport={viewport}
        baseMapStyle={account.defaultBaseMap.mapboxStyle}
        account={account}
        layers={layers}
      />

      {activeFloodLayerName && (
        <ActiveFloodLayerBadge style={{ marginRight: "-8px" }}>
          {activeFloodLayerName}
        </ActiveFloodLayerBadge>
      )}
      <Expand>
        <SquareLink
          onClick={trackExpandAction}
          to={{
            pathname: getPath("explore"),
            state: { prevLocation },
            search: queryString.stringify({
              lat: latitude,
              lng: longitude,
              firmId: visibleFIRM()?.id,
            }),
          }}
          data-testid="expand"
        >
          <Icon iconName="expand" color="contentPrimary" size="16" />
        </SquareLink>
      </Expand>
    </Container>
  );
};
