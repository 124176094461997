import React from "react";
import { useTranslation } from "react-i18next";

import { setTitle } from "../../utils/title";
import Layout from "./Layout";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "./utils";

import { EmptyState } from "../Common/EmptyState";
import { spacing } from "../../stitches.config";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";

const NoPropertyPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);

  setTitle(`No Property Found | ${accountTitle()}`);

  return (
    <Layout>
      <div style={{ marginTop: spacing["3xl"].value }}>
        <EmptyState
          body={t("property-no-results-subtext")}
          title={t("property-no-results-header")}
          icon="missing-location"
          primaryButtonProps={{
            label: t("property-no-results-button-return-home"),
            onClick: () => history.push("/"),
          }}
          buttonLinkProps={{
            label: t("get-help"),
            onClick: () => history.push(buildLink("getHelp")),
          }}
        />
      </div>
    </Layout>
  );
};

export default NoPropertyPage;
