import React, { ReactNode } from "react";

import { HomeButton } from "./HomeButton";

import { Title } from "../Common/Typography";
import Divider from "../Common/Divider";
import { Controls, Container } from "./__styles__/PageSubheader";

export const PageSubheader = ({
  title,
  children,
  showHomeButton = true,
  showDivider = true,
  additionalControls,
}: {
  title?: Maybe<String>;
  children?: ReactNode;
  showHomeButton?: boolean;
  showDivider?: boolean;
  additionalControls?: ReactNode;
}) => (
  <Container>
    {showHomeButton && (
      <Controls>
        <HomeButton />
        {additionalControls}
      </Controls>
    )}
    {title && (
      <Title size="large" type="regular" style={{ margin: "24px 0px" }}>
        {title}
      </Title>
    )}
    {children}
    {showDivider && <Divider margins="20px 0" />}
  </Container>
);
