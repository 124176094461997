import React, { useContext } from "react";

import { buildLink } from "common/routing";
import { GetDocumentUploadsQuery } from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { buildGuestPropertyURL } from "common/utils/url";
import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
export interface Props {
  documentUpload: GetDocumentUploadsQuery["documentUploads"]["data"][number];
}

const ActionButton = ({ documentUpload }: Props) => {
  const account = useContext(AuthContext).account!;

  const property = documentUpload.property;

  if (!property) return null;

  const downloadName = property.fullAddress
    ? property.fullAddress.replace(/,?[ ]/g, "-")
    : `property-${property.id}`;

  const download = `${downloadName}-EC.pdf`;

  const actions: Array<ActionsProps> = [
    {
      label: "View File",
      href: buildLink("guestDocumentUploadFile", { id: documentUpload.id }),
      target: "_blank",
    },
    {
      label: "Download PDF",
      href: buildLink(
        "documentUploadFile",
        { id: documentUpload.id },
        { accountId: account.id }
      ),
      download: download,
      target: "_blank",
    },
    {
      label: "Go to Property Profile",
      href: buildGuestPropertyURL({
        subdomain: account.subdomain,
        propertyId: property.id,
      }),
    },
  ];

  return <DropdownMenu actions={actions} />;
};

export default ActionButton;
