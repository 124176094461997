import React from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import {
  SELECTED_LANGUAGE_KEY,
  updateSelectedLanguage,
} from "../../utils/i18n/utils";
import { ExtendedApolloClient } from "../../apollo";
import { AuthContext } from "./AuthContext";
import { authorized } from "common/authorization";
import { useGetGuestAccountDetailsQuery } from "../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { setTitle } from "../../utils/title";
import { CurrentAdmin } from "./types";
import UnavailablePage from "../Guest/UnavailablePage";
import { FEATURE_FLAG_NAME, SUPPORTED_LANGUAGES } from "common/constants";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import { LoadingContainer } from "./__styles__/GuestAuthContext";
import { accountSupportsElevationCertificates } from "../../utils/documentUploads";

interface Props {
  children: React.ReactNode;
}
const GuestAuthContextProvider = ({ children }: Props) => {
  const browserLanguage = navigator.language.split("-")[0];
  const { t, i18n } = useTranslation();
  const { getValue, setValue } = useLocalStorage({
    key: SELECTED_LANGUAGE_KEY,
  });
  const client = useApolloClient() as ExtendedApolloClient;

  const { data, loading, error } = useGetGuestAccountDetailsQuery({
    onCompleted: data => {
      const supportedLanguages =
        data.account?.publicPortal.supportedLanguages || [];

      const defaultLanguage = SUPPORTED_LANGUAGES.ENGLISH;
      const selectedLanguage = getValue();

      if (
        !selectedLanguage &&
        browserLanguage !== defaultLanguage &&
        supportedLanguages.includes(browserLanguage as SUPPORTED_LANGUAGES)
      ) {
        void updateSelectedLanguage({
          language: browserLanguage as SUPPORTED_LANGUAGES,
          updateStaticTranslations: i18n.changeLanguage,
          updateDynamicTranslations: client.updateLanguage,
          updateLocalStorage: setValue,
        });
      }
    },
  });

  if (loading) {
    setTitle(t("common-loading-state"));
    return (
      <LoadingContainer>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </LoadingContainer>
    );
  }

  if (error || !data?.account) return null;

  const account = data.account;

  const supportsElevationCertificates =
    accountSupportsElevationCertificates(account);

  return account.publicPortal.enabled ? (
    <AuthContext.Provider
      value={{
        account,
        user: null,
        admin: null,
        isGuest: true,
        setAdmin: (_admin: CurrentAdmin) => {},
        isFeatureEnabled: (_: FEATURE_FLAG_NAME) => false,
        authorized: authDetails =>
          authorized({
            entity: {
              role: { name: "guest" },
              canViewPersonalIdentifiableInformation: false,
            },
            ...authDetails,
          }),
        supportsElevationCertificates,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <UnavailablePage />
  );
};

export default GuestAuthContextProvider;
