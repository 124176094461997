import React from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { Button } from "../../Common/Button";
import Wrapper from "../../Inputs/Wrapper";
import { formatDateString } from "common/utils/dates";
import {
  HelpOptionReasonCode,
  InformationRequestStatus,
  PublicInformationRequestInput,
} from "../../../generated/graphql";
import { Text, Textarea } from "../../Inputs/react-hook-form";
import MultiselectRadio from "../../Inputs/MultiselectRadio";
import { Search, SearchResultProps } from "../../Search";
import { isValidEmail } from "common/utils/strings";

import { Row } from "../../Common/__styles__/Layout";
import { LineBlock } from "./__styles__/Form";

export const createSubject = (subject: string[], comment: string) => {
  const commentSnippet = comment ? `Comment: ${comment}` : null;
  let notes = subject.join(", ");

  if (notes && commentSnippet) {
    return notes + `, ${commentSnippet}`;
  } else if (commentSnippet || notes) {
    return commentSnippet ?? notes;
  } else {
    return null;
  }
};

export interface FormProps {
  data: {
    propertyId?: string;
    address?: Maybe<string>;
    submittedAt: Date;
    longitude?: Maybe<number>;
    latitude?: Maybe<number>;
  };
  subjectOptions: {
    value: string;
    label: string;
    reasonCodes?: Maybe<Array<Maybe<HelpOptionReasonCode>>>;
  }[];
  onSave: (data: PublicInformationRequestInput) => void;
}

interface FormStructure {
  name: string;
  email: string;
  phone: Maybe<string>;
  address: string;
  subject: string[];
  comment: string;
  submittedAt: string;
  propertyId: Maybe<string>;
  longitude: Maybe<number>;
  latitude: Maybe<number>;
}

export default ({ data, subjectOptions, onSave }: FormProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = React.useState(false);

  const reasonCodeParam = queryString.parse(location.search).reasonCodes as
    | string
    | undefined;
  const reasonCodes = (reasonCodeParam?.split(",") ??
    []) as Array<HelpOptionReasonCode>;

  const subject = subjectOptions
    .filter(subjectOption =>
      reasonCodes.some(queryOption =>
        subjectOption.reasonCodes?.includes(queryOption)
      )
    )
    .map(m => m.value);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: {
      address: data.address ?? "",
      longitude: data.longitude,
      latitude: data.latitude,
      subject,
      comment: "",
    },
  });

  const propertyId = data.propertyId ?? null;
  const submittedAt = formatDateString({
    format: "YYYY-MM-DD",
    dateString: data.submittedAt.toDateString(),
  });

  const otherOptionIndex = subjectOptions.findIndex(
    option => option.label === "Other"
  );
  if (otherOptionIndex !== -1) {
    subjectOptions.push(subjectOptions.splice(otherOptionIndex, 1)[0]!);
  }

  const handleSearchResult = (searchResult: SearchResultProps) => {
    const address = searchResult.address;
    const { longitude, latitude } = searchResult.point;

    setValue("address", address);
    setValue("longitude", longitude);
    setValue("latitude", latitude);
  };

  const requiredField = t("common-required-field");
  const addressLabel = t("get-help-form-field-address");

  return (
    <LineBlock>
      <form
        onSubmit={handleSubmit(userProvidedData => {
          setSubmitted(true);
          onSave({
            propertyId,
            submittedAt,
            type: "web",
            name: userProvidedData.name,
            email: userProvidedData.email,
            phone: userProvidedData.phone,
            address: userProvidedData.address,
            requestedHelpWith: createSubject(
              userProvidedData.subject.map(
                selectionId =>
                  subjectOptions.find(f => f.value === selectionId)!.label
              ),
              userProvidedData.comment
            ),
            accountInformationRequestHelpOptionIds: userProvidedData.subject,
            longitude: userProvidedData.longitude!,
            latitude: userProvidedData.latitude!,
            status: InformationRequestStatus.OPEN,
          });
        })}
      >
        <Row>
          <Text
            label={t("get-help-form-field-name")}
            error={errors.name?.message}
            size="large"
            required
            {...register("name", {
              required: requiredField,
            })}
            labelTabIndex={-1}
          ></Text>
        </Row>
        <Row>
          <Text
            label={t("get-help-form-field-email")}
            error={errors.email?.message}
            size="large"
            required
            {...register("email", {
              validate: val => {
                if (!val) {
                  return requiredField;
                }
                if (!isValidEmail(val)) {
                  return t("get-help-form-field-email-error");
                }
                return;
              },
            })}
            labelTabIndex={-1}
          ></Text>
        </Row>
        <Row>
          <Text
            label={t("get-help-form-field-number")}
            size="large"
            {...register("phone")}
            labelTabIndex={-1}
          ></Text>
        </Row>
        <Row>
          {data.address ? (
            <Text
              label={addressLabel}
              error={errors.address?.message}
              size="large"
              required
              disabled={!!data.propertyId && !!data.address}
              {...register("address", { required: requiredField })}
              labelTabIndex={-1}
            ></Text>
          ) : (
            <Controller
              control={control}
              name="address"
              rules={{ required: requiredField }}
              render={({ field }) => {
                return (
                  <Wrapper
                    name="address"
                    label={addressLabel}
                    error={errors.address?.message}
                    required
                    labelTabIndex={-1}
                    printValue={field.value}
                  >
                    <Search
                      handleResultClick={handleSearchResult}
                      originalValue={field.value}
                      addressOnly
                    />
                  </Wrapper>
                );
              }}
            />
          )}
        </Row>
        <Row>
          <Controller
            control={control}
            name="subject"
            rules={{
              validate: val => {
                if (!val.length) {
                  return requiredField;
                }
                return;
              },
            }}
            render={({ field }) => {
              return (
                <MultiselectRadio
                  name="subject"
                  value={field.value}
                  type="radio"
                  options={subjectOptions}
                  onChange={field.onChange}
                  label={t("get-help-form-field-help")}
                  multiselectDescription={t("get-help-form-field-help-subtext")}
                  error={(errors.subject as unknown as FieldError)?.message}
                />
              );
            }}
          />
        </Row>

        <Row>
          <Textarea
            label={t("get-help-form-field-comment")}
            {...register("comment")}
          ></Textarea>
        </Row>
        <Row>
          <Button
            type="submit"
            disabled={submitted}
            size="medium"
            styleVariant="primary"
          >
            {t("button-submit-request")}
          </Button>
        </Row>
      </form>
    </LineBlock>
  );
};
