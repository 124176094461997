import React, { useContext } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { buildLink } from "common/routing";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import { accountTitle, useIsMobileWidth } from "./utils";
import { Button } from "../Common/Button";

export const HomeButton = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const account = useContext(AuthContext).account!;

  return (
    <Button
      leftIconName="chevron-left"
      styleVariant="ghostGrey"
      size="small"
      onClick={() => {
        track("Homepage link clicked");
        history.push(buildLink("guestHome", { accountId: account.id }));
      }}
      style={{ padding: 0 }}
    >
      {isMobile
        ? t("common-homepage")
        : t("button-go-to-homepage", { accountName: accountTitle() })}
    </Button>
  );
};
