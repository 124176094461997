import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import DocumentUploadsList from "../../AddressPanel/DocumentUploads/List";
import { FilesTabProps, GuestPropertyTabProps } from ".";
import { OBJECT_TYPE } from "common/constants";
import { track } from "../../../utils/tracking";
import DocumentUploadsListItem from "../../AddressPanel/DocumentUploads/ListItem";
import Disclaimer from "../../Common/Disclaimer";
import { getDisclaimerText } from "common-client/utils/disclaimerOverrides";
import { AuthContext } from "../../Authorization/AuthContext";
import { DisclaimerName } from "../../../generated/graphql";
import { buildLink } from "common/routing";

import { DocumentListContainer } from "./__styles__/PropertyPage";

export const Files = ({
  propertyId,
  documentUploads,
  propertyLabel,
}: GuestPropertyTabProps & FilesTabProps) => {
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();

  const disclaimerText = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.DOCUMENTS,
    isPublic: true,
    defaultDisclaimers: {
      public: t("disclaimers-documents", {
        accountName: account?.name || "",
        getHelpLink: buildLink(
          "getHelp",
          { accountId: account?.id || "" },
          { propertyId }
        ),
      }),
    },
  });

  return (
    <DocumentListContainer data-testid="documents">
      <DocumentUploadsList
        object={{
          id: propertyId,
          objectType: OBJECT_TYPE.PROPERTY,
          objectLabel: propertyLabel,
        }}
      >
        {documentUploads.map(documentUpload => (
          <div
            key={documentUpload.id}
            onClick={() => {
              track("PPP View File Clicked", {
                propertyId,
                streetAddress: propertyLabel,
                documentUploadId: documentUpload.id,
              });
            }}
          >
            <DocumentUploadsListItem
              documentUploadId={documentUpload.id}
              isDeletable={false}
              documentUploadMimeType={documentUpload.mimeType}
              issuedAt={documentUpload.issuedAt}
              // Because this is coming from the public site, we can assume the document is not hidden
              hiddenFromPublic={false}
              accountDocumentType={documentUpload.accountDocumentType}
              filename={documentUpload.originalFilename}
              createdAt={documentUpload.createdAt}
            />
          </div>
        ))}
      </DocumentUploadsList>
      <Disclaimer message={disclaimerText} />
    </DocumentListContainer>
  );
};
