import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { GuestPropertyTabProps, RecordsTabProps } from ".";
import { TabGroupContainer } from "./__styles__/PropertyPage";
import TabGroup from "../../Common/TabGroup";
import useNavigationTab from "../../../hooks/useNavigationTabs";
import { map } from "lodash";
import Submissions from "../../AddressPanel/Submissions";
import { spacing } from "../../../stitches.config";
import { getOptionalTabs } from "../../../utils/submissions";
import { SUBMISSION_CATEGORY } from "common/constants";
import { AuthContext } from "../../Authorization/AuthContext";
import { RECORDS_TAB_NAME } from "common/routing";
import Improvements from "../../AddressPanel/Improvements";

const SISD_CATEGORY =
  SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE;

export const Records = ({
  latitude,
  longitude,
  propertyLabel,
  submissions,
}: GuestPropertyTabProps & RecordsTabProps) => {
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();

  const defaultVisibleSubmissionTypeCategories =
    account?.submissionTypes
      .filter(submissionType => !submissionType.hiddenFromPublicByDefault)
      .map(st => st.category) ?? [];
  const existingSubmissionTypeCategories = submissions.map(
    submission => submission.submissionTypeVersion.submissionType.category
  );
  const categories = new Set([
    ...defaultVisibleSubmissionTypeCategories,
    ...existingSubmissionTypeCategories,
  ]);

  if (account?.publicPortal.hideSISD) {
    categories.delete(SISD_CATEGORY);
  } else {
    categories.add(SISD_CATEGORY);
  }

  const optionalTabs = getOptionalTabs(t);
  const tabs = optionalTabs
    .filter(({ category }) => categories.has(category))
    .map(({ recordTabName, title }) => ({
      value: recordTabName,
      label: title,
    }));

  const defaultTab = tabs[0];

  if (!defaultTab) {
    // This should never happen, as the records tab shouldn't show if
    // there aren't available tabs, so this is just a safe-guard
    return null;
  }

  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab.value,
    allowedTabs: map(tabs, "value"),
    queryParamName: "recordTab",
  });

  const currentOptionalTab = optionalTabs.find(
    ({ category, recordTabName }) =>
      tab === recordTabName && category !== SISD_CATEGORY
  );

  return (
    <>
      <TabGroupContainer data-testid="tabs">
        <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
      </TabGroupContainer>
      <div style={{ padding: `0 ${spacing.l}` }} data-testid="records">
        {tab === RECORDS_TAB_NAME.IMPROVEMENTS && (
          <Improvements
            latitude={latitude}
            longitude={longitude}
            address={propertyLabel}
            publicOnly={true}
          />
        )}
        {currentOptionalTab && (
          <Submissions
            category={currentOptionalTab.category}
            title={currentOptionalTab.title}
            titleWithinCopy={currentOptionalTab.titleWithinCopy}
            latitude={latitude}
            longitude={longitude}
            address={propertyLabel}
          />
        )}
      </div>
    </>
  );
};
