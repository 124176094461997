import React, { useContext } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { isNil, map } from "lodash";
import { useTranslation } from "react-i18next";

import MapPreview from "../../Maps/Preview";
import { LayerContextProvider } from "../../Maps/layers";
import { isMedium, isSmall } from "../../../utils/size";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AuthContext } from "../../Authorization/AuthContext";
import useNavigationTab from "../../../hooks/useNavigationTabs";
import TabGroup from "../../Common/TabGroup";
import {
  GetResidentPropertyInfoQuery,
  SubmissionIntakeSource,
  useGetResidentPropertyInfoQuery,
} from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import { setTitle } from "../../../utils/title";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import Layout from "../Layout";
import { PrintButton } from "../PrintButton";
import UnavailablePage from "../UnavailablePage";
import { HELPSCOUT_BEACON_TYPES, useHelpscoutBeacon } from "../utils";
import { PropertyCarousel } from "../../AddressPanel/PropertyOverview/Carousel";
import {
  AddressInfoContainer,
  CarouselContainer,
  LoadingContainer,
  MapboxAttributionContainer,
  MapboxIconContainer,
  PropertyDetailsContainer,
  TabGroupContainer,
} from "./__styles__/PropertyPage";
import { formatCoordinates } from "common/utils/coordinates";
import { AddressPanelContext } from "../../AddressPanel/AddressPanelContext";
import { getValueForParcelFieldNameOverride } from "common-client/utils/parcels";
import AccessDeniedPage, { ACCESS_DENIED_RESOURCE } from "../AccessDeniedPage";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { spacing } from "../../../stitches.config";
import { OBJECT_TYPE, SUBMISSION_TYPE_MODULE } from "common/constants";
import { createSubmissionRedirect } from "../../Common/CreateSubmissionButton/utils";
import { Overview } from "./Overview";
import { Files } from "./Files";
import { Records } from "./Records";
import { getObjectDisplay } from "common-client/utils/customObject";
import { PageSubheader } from "../PageSubheader";

interface URLParams {
  accountId: string;
  propertyId: string;
}
interface Props extends RouteComponentProps<URLParams> {
  print: typeof window.print;
}

enum TAB_NAMES {
  OVERVIEW = "overview",
  FILES = "documents",
  RECORDS = "records",
}

const useBuildTabs = ({
  existingSubmissions,
}: {
  existingSubmissions?: NonNullable<
    GetResidentPropertyInfoQuery["property"]
  >["submissions"];
}) => {
  const { account } = useContext(AuthContext);
  const { t } = useTranslation();
  const defaultVisibleSubmissionTypes = account?.submissionTypes.filter(
    submissionType => !submissionType.hiddenFromPublicByDefault
  );

  const OVERVIEW_TAB = { value: TAB_NAMES.OVERVIEW, label: t("tab-overview") };
  const CERTIFICATES_TAB = {
    value: TAB_NAMES.FILES,
    label: t("common-files"),
  };

  const RECORDS_TAB = {
    value: TAB_NAMES.RECORDS,
    label: t("property-records"),
  };

  const tabs: Array<{ value: TAB_NAMES; label: string }> = [
    OVERVIEW_TAB,
    CERTIFICATES_TAB,
  ];

  if (
    existingSubmissions?.length ||
    defaultVisibleSubmissionTypes?.length ||
    !account?.publicPortal.hideSISD
  ) {
    tabs.splice(1, 0, RECORDS_TAB);
  }
  return tabs;
};

export type GuestPropertyTabProps = {
  propertyId: string;
  latitude: number;
  longitude: number;
  propertyLabel: string;
};

export type FilesTabProps = {
  documentUploads: NonNullable<
    GetResidentPropertyInfoQuery["property"]
  >["documentUploads"];
};

export type RecordsTabProps = {
  submissions: NonNullable<
    GetResidentPropertyInfoQuery["property"]
  >["submissions"];
};

const TabBody = (
  props: GuestPropertyTabProps &
    FilesTabProps &
    RecordsTabProps & {
      tab: TAB_NAMES;
    }
) => {
  const { tab, documentUploads, ...childProps } = props;
  return (
    <>
      {tab === TAB_NAMES.OVERVIEW && <Overview {...childProps} />}
      {tab === TAB_NAMES.RECORDS && <Records {...childProps} />}
      {tab === TAB_NAMES.FILES && (
        <Files {...childProps} documentUploads={documentUploads} />
      )}
    </>
  );
};

const PropertyPage = ({ match, print = window.print }: Props) => {
  const { propertyId } = match.params;
  const { account } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();

  const fetchPolicy = "network-only";
  const query = useLocation().search;
  const {
    data,
    loading: loadingProperty,
    error,
  } = useGetResidentPropertyInfoQuery({
    variables: { ...match.params, accountId: account!.id },
    fetchPolicy,
    onCompleted: data => {
      const urlParams = new URLSearchParams(query);
      const source = urlParams.get("src");

      track("PPP Viewed", {
        propertyId,
        source,
        streetAddress: data.property?.streetAddress,
      });
    },
  });

  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);

  const tabs = useBuildTabs({
    existingSubmissions: data?.property?.submissions,
  });
  const defaultTab = tabs[0];
  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab!.value,
    allowedTabs: map(tabs, "value"),
  });

  const { width } = useWindowSize();

  if (loadingProperty) {
    return (
      <LoadingContainer>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </LoadingContainer>
    );
  }

  if (!data || !data.property || !data.account) {
    return <AccessDeniedPage resource={ACCESS_DENIED_RESOURCE.PROPERTY} />;
  }

  if (error || !account) {
    return <UnavailablePage />;
  }

  let mapHeight = 400;
  if (isSmall() || isMedium()) {
    mapHeight = width * 0.52;
  }

  const {
    fullAddress,
    streetAddress,
    city,
    state,
    zipcode,
    latitude,
    longitude,
    documentUploads,
  } = data.property;

  const parcelId = data.property.parcel?.parcelId;
  const title = streetAddress ? streetAddress : t("property-missing-address");
  const formattedCoordinates = formatCoordinates({ latitude, longitude });

  const subTitle =
    isNil(city) || isNil(state) || isNil(zipcode)
      ? formattedCoordinates
      : `${city}, ${state} ${zipcode}`;

  setTitle(`${title} (${formattedCoordinates})`);

  const parcelIdLabel = getValueForParcelFieldNameOverride({
    overrides: account.parcelFieldNameOverrides,
    name: "parcelId",
  });

  return (
    <AddressPanelContext.Provider
      value={{
        loadingProperty,
        property: { ...data.property, hiddenFromPublic: false },
      }}
    >
      <LayerContextProvider account={{ ...data.account, savedViews: [] }}>
        <Layout propertyId={propertyId} hasGutters={true}>
          <div style={{ paddingBottom: spacing.m.value }}>
            <PageSubheader
              showDivider={false}
              additionalControls={<PrintButton print={print} />}
            />
          </div>

          <MapPreview
            height={`${mapHeight}px`}
            width={"100%"}
            latitude={latitude!}
            longitude={longitude!}
            account={data.account!}
          />

          <MapboxAttributionContainer>
            <MapboxIconContainer>
              <Icon icon={Icons.MAPBOX_ATTRIBUTION} />
            </MapboxIconContainer>
            <span>© Mapbox, © OpenStreetMap</span>
          </MapboxAttributionContainer>
          <PropertyDetailsContainer data-testid="property-info-header">
            <AddressInfoContainer>
              <h1>{title}</h1>
              <h2>{subTitle}</h2>
              <h2 data-testid="parcel-id">{`${parcelIdLabel}: ${
                parcelId ?? "-"
              }`}</h2>

              <div style={{ marginTop: spacing.m.value }}>
                <CreateSubmissionButton
                  buttonText={t("property-button-new-record")}
                  hideIfNoOptions={true}
                  disabled={false}
                  objectDisplay={getObjectDisplay({
                    objectType: OBJECT_TYPE.PROPERTY,
                    object: {
                      fullAddress: streetAddress,
                      latitude,
                      longitude,
                    },
                  })}
                  onSubmit={submissionTypeId =>
                    createSubmissionRedirect({
                      history,
                      submissionTypeId,
                      objectId: propertyId,
                      objectType: OBJECT_TYPE.PROPERTY,
                    })
                  }
                  submissionTypeFilter={submissionType =>
                    submissionType.intakeSource ===
                      SubmissionIntakeSource.EXTERNAL &&
                    // Don't show EC Submissions since they are handled in another place
                    !submissionType.modules.includes(
                      SUBMISSION_TYPE_MODULE.EC_SUBMISSION
                    ) &&
                    submissionType.attachments
                      .map(attachment => attachment.type)
                      .includes(OBJECT_TYPE.PROPERTY)
                  }
                />
              </div>
            </AddressInfoContainer>
            <CarouselContainer>
              {latitude && longitude && (
                <PropertyCarousel
                  latitude={latitude}
                  longitude={longitude}
                  address={fullAddress}
                  propertyId={propertyId}
                  size={"small"}
                />
              )}
            </CarouselContainer>
          </PropertyDetailsContainer>

          <TabGroupContainer data-testid="tabs">
            <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
          </TabGroupContainer>
          <TabBody
            tab={tab}
            documentUploads={documentUploads}
            submissions={data.property.submissions}
            propertyId={propertyId}
            latitude={latitude}
            longitude={longitude}
            propertyLabel={streetAddress ?? formattedCoordinates}
          />
        </Layout>
      </LayerContextProvider>
    </AddressPanelContext.Provider>
  );
};

export default PropertyPage;
