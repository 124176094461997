import React from "react";
import { setTitle } from "../../utils/title";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo-white.svg";

import { HELPSCOUT_BEACON_TYPES, useHelpscoutBeacon } from "./utils";
import { spacing } from "../../stitches.config";
import { EmptyState } from "../Common/EmptyState";
import { buildLink } from "common/routing";

import { Page } from "./__styles__/Layout";
import { StyledHeader } from "./__styles__/UnavailablePage";

const UnavailablePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);
  setTitle(t("tab-page-unavailable"));

  return (
    <Page>
      <Header />
      <div style={{ marginTop: spacing["3xl"].value }}>
        <EmptyState
          body={t("page-unavailable-subtext")}
          title={t("page-unavailable-header")}
          icon="missing-location"
          primaryButtonProps={{
            label: t("page-unavailable-button-contact"),
            onClick: () => history.push(buildLink("getHelp")),
          }}
        />
      </div>
    </Page>
  );
};

const Header = () => {
  return (
    <StyledHeader>
      <div>
        <img src={Logo} />
      </div>
    </StyledHeader>
  );
};

export default UnavailablePage;
