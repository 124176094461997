import React, { useContext } from "react";
import { useLocation } from "react-router";
import { entries, isEmpty, omit, pickBy } from "lodash";
import { useTranslation } from "react-i18next";

import GetHelpForm from "./Form";
import ThumbsUp from "../../Common/Icons/ThumbsUp";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import { setTitle } from "../../../utils/title";
import { track } from "../../../utils/tracking";
import {
  ContactInformation,
  useCreatePublicInformationRequestMutation,
  useGetGetHelpDataQuery,
  useGetPropertyPublicDetailsQuery,
} from "../../../generated/graphql";
import Layout from "../Layout";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "../utils";
import { PageSubheader } from "../PageSubheader";
import { AuthContext } from "../../Authorization/AuthContext";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Body, Title } from "../../Common/Typography";
import { TranslationKeys } from "common/utils/i18n/fallbackEnTranslations";

import "./__styles__/index.scss";
import { FlexRow } from "../../Common/__styles__/Layout";

type ContactInformationType = keyof Omit<
  ContactInformation,
  "id" | "__typename"
>;

const CONTACT_FIELD_TO_TRANSLATION_KEY = {
  name: "get-help-contact-name",
  title: "get-help-contact-title",
  phoneNumber: "get-help-contact-phone-number",
  email: "get-help-contact-email",
} as const satisfies Record<ContactInformationType, TranslationKeys>;

const SuccessMessage = () => {
  const { t } = useTranslation();
  return (
    <div styleName="line-block">
      <div styleName="success-message">
        <Title size="default" type="regular">
          {t("get-help-confirmation-page-header")}
        </Title>
        <div styleName="icon-wrapper">
          <ThumbsUp />
        </div>
        <Body size="large" type="regular">
          {t("get-help-confirmation-page-subtext")}
        </Body>
      </div>
    </div>
  );
};

const GetHelpPage = () => {
  const { t } = useTranslation();
  const title = t("get-help");
  setTitle(`${title} | ${accountTitle()}`);
  const account = useContext(AuthContext).account!;

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const location = useLocation();
  const [success, setSuccess] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get("propertyId") || undefined;

  const {
    data: propertyData,
    loading: propertyLoading,
    error: propertyError,
  } = useGetPropertyPublicDetailsQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId! },
  });

  const {
    data: getHelpData,
    loading: getHelpLoading,
    error: contactError,
  } = useGetGetHelpDataQuery();

  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.GET_HELP);

  const [create] = useCreatePublicInformationRequestMutation({
    onCompleted: () => {
      track("Submitted Get Help Form");
      addSuccessToast(t("get-help-confirmation-toast-success"));
      setSuccess(true);
    },
    onError: () => {
      addErrorToast(t("get-help-confirmation-toast-error"));
    },
  });

  if (propertyLoading || getHelpLoading) {
    setTitle(title);
    return (
      <div styleName="loading">
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </div>
    );
  }

  if (propertyError || contactError) {
    return null;
  }

  const property = propertyData?.property;
  const informationRequestInput: {
    propertyId?: string;
    address?: Maybe<string>;
    longitude?: Maybe<number>;
    latitude?: Maybe<number>;
    submittedAt: Date;
  } = property
    ? {
        propertyId: propertyId,
        address: property.fullAddress,
        longitude: property.longitude,
        latitude: property.latitude,
        submittedAt: new Date(),
      }
    : { submittedAt: new Date() };

  const contactInformation = omit(getHelpData?.account?.contactInformation, [
    "id",
    "__typename",
  ]);

  const nonEmptyContactInformation = pickBy(
    contactInformation || {},
    info => !isEmpty(info)
  );
  const showContactInformation = !isEmpty(nonEmptyContactInformation);

  const subjectOptions =
    getHelpData!.account!.publicPortal.accountInformationRequestHelpOptions.map(
      option => {
        return {
          value: option.id,
          label: option.text,
          reasonCodes: option.reasonCodes,
        };
      }
    );

  return (
    <Layout propertyId={property?.id} hasGutters={true}>
      {success ? (
        <SuccessMessage />
      ) : (
        <>
          <PageSubheader title={title}>
            <Body
              size="large"
              type="regular"
              color="contentSecondary"
              style={{ marginBottom: "16px" }}
            >
              {t("get-help-subtext", { accountName: account.name })}
            </Body>
            {showContactInformation && (
              <>
                <Body size="large" type="regular" color="contentSecondary">
                  {t("get-help-contact-text")}
                </Body>
                {entries(nonEmptyContactInformation).map(([field, value]) => {
                  const contactField = field as ContactInformationType;
                  return (
                    <FlexRow key={field} style={{ gap: "4px" }}>
                      <Body size="large" type="emphasis" as="span">
                        {t(CONTACT_FIELD_TO_TRANSLATION_KEY[contactField])}:
                      </Body>
                      <Body
                        size="large"
                        type="regular"
                        color="contentSecondary"
                      >
                        {value}
                      </Body>
                    </FlexRow>
                  );
                })}
              </>
            )}
          </PageSubheader>
          <div styleName="container">
            <GetHelpForm
              data={informationRequestInput}
              subjectOptions={subjectOptions}
              onSave={async data => {
                await create({ variables: { data } });
              }}
            />
          </div>
        </>
      )}
    </Layout>
  );
};

export default GetHelpPage;
